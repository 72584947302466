<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="realtorid">
          MLS®/Board user ID
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!realtorid"
          title="Realtor Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="realtorid"
          title="Realtor Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <div class="panel panel-default">
      <div
        class="panel-title"
        style="padding-bottom: 25px;margin-bottom: 20px;"
      >
        <i class="fa fa-bars" />  MLS® user ID Setting
      </div>
            <el-row :gutter="20">
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="12"
              :xl="12"
            >
              <el-form-item
                label="MLS®/Board user ID"
                prop="realtor_mls_userid"
              >
                <el-input
                  v-model="data.realtor_mls_userid"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
          </el-row>
    </div>
  </el-form>
</template>
<script>
import AccountService from "@/api/account.service";
  export default {
    data() {
         var checkMlsid = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the MLS®/Board user ID.'));
        } else {
          callback();
        }
      };      
      return {
        showAlertAdded: false,
        labelPosition: 'left',
        realtorid: this.$route.params.realtorid,       
        data: {
          realtor_board: null
        },
        rules: {
          realtor_mls_userid: [
            { validator: checkMlsid, trigger: 'blur' }
          ],
        }
      };
    },     
	mounted() {
    if(this.realtorid){
      this.getrealtor();
    }
  },
   methods: {
       submitForm(formName) {
        this.$refs[formName].validate((valid) => {
         if (valid && !this.realtorid) {
             return AccountService.add(this.data).then(response => {
                this.showAlertAdded=true;
              });
          } else if (valid && this.realtorid) {
            return AccountService.update(this.realtorid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
	getrealtor(){
    return AccountService.get(this.realtorid).then(response => {
        this.data = response.data.data;
      });
	}
    }
  }
</script>
<style lang="scss" scoped>
 .panel-title{
      border-bottom: 1px solid #9a83835c;
      padding-bottom: 10px;
      margin-bottom: 10px; 
      text-align: left;
  }

.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
</style>